import React, { Component } from 'react';
import { Content, Row } from 'adminlte-2-react';


class Dashboard extends Component {
  state = {}

  render() {
    return (
      <Content title="Dashboard" subTitle="Version 1.0">
        <Row />
      </Content>
    );
  }
}

export default Dashboard;
